const navList = [
  {
    id: 1,
    name: 'About',
    linkTo: 'about'
  },
  {
    id: 2,
    name: 'Problems',
    linkTo: 'problems'
  },
  {
    id: 3,
    name: 'Solution',
    linkTo: 'solution'
  },
  // {
  //   id: 5,
  //   name: 'Teams',
  //   linkTo: 'teams'
  // },
  {
    id: 6,
    name: 'Partners',
    linkTo: 'partners',
  },
  {
    id: 6,
    name: 'Contact',
    linkTo: 'contact',
  },
]

export default navList;