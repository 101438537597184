import sdgs from '../../img/sdg..png'

const SdgsAddress = () => {
  return (
    <div className="sdgs-container">
      <h1>SDGs Addressed</h1>
      <div className="sdgs-img">
        <img src={sdgs} alt="sdgs" />
      </div>
    </div>
   );
}

export default SdgsAddress;