import HomeSlide from './about';
import AboutSection from './aboutSection';
import Contact from './contact';
import Footer from './footer';

import './home.css'
import Partners from './partners';
import Problems from './problems';
import SdgsAddress from './sdgs_address';
import SocialImpact from './socialImpact';
import Solutions from './solutions';
import SolutionSlide from './solutionsSlide';
const Home = () => {
  return (
    <div className="home">
      <div id='about'>
        <HomeSlide />
        <AboutSection />
      </div>
      <div id='problems'>
        <Problems/>
      </div>
      <div id='solution'>
        <Solutions />
        <SolutionSlide />
        <SocialImpact />
        <SdgsAddress />
      </div>
      <div id="partners">
        <Partners />
      </div>
      <div id="contact">
        <Contact />
      </div>
      <div className="mc-green-map">
        <Footer />
      </div>
    </div>
   );
}

export default Home;