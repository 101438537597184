import ScrollTopButton from "./components/button/scrollTopButton";
import Home from "./components/home/home";
import Navbar from "./components/navbar/navbar";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Home />
      <ScrollTopButton />
    </div>
  );
}

export default App;
