import def1 from '../../img/old_cooking.jpg'
import def6 from "../../img/def6.avif";
import charcoal from '../../img/choal.jpeg'


const Problems = () => {
  return (
    <div className="problems-container">
      <h1>Problems</h1>
      <div className="problems-cards-container">
        <div className="problems-card">
          <div className="problem-img">
            <img src={def1} alt="deforestation" />
          </div>
          <div className="item-text">
            <p>
              MC-GREEN is committed to addressing the pressing issue of limited
              access to clean cooking energy in Rwandan households. Many
              families in Rwanda still depend on traditional biomass sources,
              such as firewood and charcoal, for cooking. This reliance on
              traditional fuels not only leads to inefficient cooking methods
              but also has adverse effects on the environment and public health.
            </p>
          </div>
        </div>
        <div className="problems-card">
          <div className="problem-img">
            <img src={charcoal} alt="deforestation" />
          </div>
          <div className="item-text">
            <p>
            Traditional cooking methods dominate Rwanda, with 93% relying on firewood in rural areas and 65% using charcoal in urban settings. This dependence on traditional fuels contributes to indoor air pollution, causing over 7,800 premature deaths yearly. Currently, 80% of Rwandans still use firewood, but by 2024, we aim to reduce that figure to 42%, aligning with the Paris Agreement's emissions reduction target of 38% from 2021-2030.
            </p>
          </div>
        </div>
        <div className="problems-card">
          <div className="problem-img">
            <img src={def6} alt="deforestation" />
          </div>
          <div className="item-text">
            <p>
            Furthermore, this dependency leads to deforestation, environmental degradation, and indoor air pollution, especially for women and children who spend up to five hours daily collecting firewood, with a regional average of 2.1 hours. This prevents them from participating in economic activities and attending school, putting them at physical risk. The economic losses from time spent cooking and collecting fuel are substantial.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Problems;
