import img3 from '../../img/improve_des.png'
import img4 from '../../img/pallets-des.png'
import img5 from '../../img/jobcreation_des.png'
import img6 from '../../img/6.jpg'
const SolutionImg = [
  {
    id: 1,
    title: 'images',
    description: 'Mc-green’s Improved cook stove',
    img: img3
  },
  {
    id: 2,
    title: 'images',
    description: 'Fuel Pallets Production',
    img: img4
  },
  {
    id: 3,
    title: 'images',
    description: 'Job creation',
    img: img5
  },
  {
    id: 4,
    title: 'images',
    description: 'Mc-green’s Improved cook stove',
    img: img6
  },
]

export default SolutionImg;