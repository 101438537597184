const Footer = () => {
  return (
    <div className="footer">
      <div className="address-section">
        <label htmlFor="country">
          <h4>Office address:</h4>
          <p>
            Niboye Cell, Road 294,avenue 350, House number 24 Kicukiro , Kigali
            City
          </p>
        </label>
        <label htmlFor="phone">
          <p>Email address</p>
          <p>mcgreenrwa1@gmail.com</p>
        </label>
        <label htmlFor="phone">
          <p>Phone number</p>
          <p>(+250) 788505309/780928644 </p>
        </label>
      </div>
      <iframe
        title="mc-green-map"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1022.7887680912028!2d30.09339444122394!3d-1.968876810755347!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19dca72aaa560095%3A0x7da2f8d21cf140c3!2smc-green!5e0!3m2!1sen!2srw!4v1698858564027!5m2!1sen!2srw"
        width={"100"}
        allowfullscreen="true"
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default Footer;
