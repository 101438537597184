import { Link } from "react-scroll";
import "./navbar.css";
import { useState } from "react";
import link from "./navItems";
import logo from "../../img/mc-green-logo.jpg";

const Navbar = () => {
  const [active, setActive] = useState("nav_menu");
  const [toggleIcon, setToggleIcon] = useState("nav_toggler");

  const handleNavToggler = () => {
    active === "nav_menu"
      ? setActive("nav_menu nav_active")
      : setActive("nav_menu");
    toggleIcon === "nav_toggler"
      ? setToggleIcon("nav_toggler toggle")
      : setToggleIcon("nav_toggler");
  };

  return (
    <nav className="nav">
      <div className="logo">
        <img src={logo} alt="mc-green logo" />
        <h2>MC-GREEN'S CO Ltd</h2>
      </div>
      <ul className={active}>
        {link.map((navlink) => (
          <li className="nav_item" key={navlink.id}>
            <Link
              to={navlink.linkTo}
              spy={true}
              smooth={true}
              offset={-70}
              duration={1000}
              className="nav_link"
              onClick={handleNavToggler}
            >
              {navlink.name}
            </Link>
          </li>
        ))}
      </ul>
      <div className={toggleIcon} onClick={handleNavToggler}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </nav>
  );
};

export default Navbar;
