const AboutSection = () => {
  return (
    <div className="about-section">
      <div className="overview">
        <h1>About us</h1>
        <p>
          MC-GREEN'S CO Ltd, was founded in Rwanda in 2021, is a pioneering
          pellet cooking company with a dual focus on customer satisfaction and
          substantial positive impact, all while ensuring financial
          profitability. We specialize in the production of "made in Rwanda"
          pellet fuel, sourced from biomass waste materials, and the
          manufacturing of gasification pellet stoves to our valued customers.
          By establishing an eco-friendly cooking solution with a renewable fuel
          supply chain, MC-GREEN'S CO contributes to creating
          environmentally-friendly jobs, promotes a circular economy,
          significantly reduces emissions, lowers cooking costs for households,
          and transforms lives. We firmly believe that our Pay in installment
          and PAYGO fuel and stove combination represents the most
          cost-effective and well-suited solution for rural, urban Rwandans and
          refugees, offering a genuinely clean, affordable, sustainable,
          dependable, and modern energy cooking solution.
        </p>
      </div>
      <div className="about-container-card">
        <div className="about-card-section">
          <div className="about-card">
            <h3>Vision</h3>
            <p>
            Our vision is to lead the way in building a sustainable and environmentally friendly cooking company in the region. We're dedicated to creating a low-carbon cooking value chain that prioritizes customers, the climate, and long-term financial sustainability.
            </p>
          </div>
          <div className="about-card">
            <h3>Mission</h3>
            <p>
            Our mission is to eliminate the need for charcoal production and charcoal cooking by offering cost-effective, low-carbon pellet fuel production and cooking solutions. We achieve this by utilizing locally sourced waste materials for fuel production. We also aim to create accessible and affordable clean cooking energy solutions for everyone, while also enhancing our production systems to meet industry standards.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
