import img1 from '../../img/trategic.jpg'
import img2 from '../../img/improve_des.png'
import img9 from '../../img/pallets-des.png'
import img12 from '../../img/pallets3.webp'
const ImgSlide = [
  {
    id: 1,
    title: 'images',
    description: 'CROPS RESIDUES AND WASTES VALORIZATION FOR CLEAN COOKING ENERGY IN RWANDA ',
    img: img1
  },
  {
    id: 2,
    title: 'images',
    description: 'CROPS RESIDUES AND WASTES VALORIZATION FOR CLEAN COOKING ENERGY IN RWANDA ',
    img: img2
  },
  {
    id: 3,
    title: 'images',
    description: 'CROPS RESIDUES AND WASTES VALORIZATION FOR CLEAN COOKING ENERGY IN RWANDA ',
    img: img12
  },
  {
    id: 4,
    title: 'images',
    description: 'CROPS RESIDUES AND WASTES VALORIZATION FOR CLEAN COOKING ENERGY IN RWANDA ',
    img: img9
  },
]

export default ImgSlide;