// import Swiper core and required modules
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ImgSlide from "./imgSlide";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";


const HomeSlide = () => {
  return (
    <div className="home_slide">
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        autoplay={true}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
      >
        {ImgSlide.map((img) => (
          <SwiperSlide key={img.id} className="swiper_img">
            <img src={img.img} alt={img.title} />
            <h3>{img.description}</h3>
          </SwiperSlide>
        ))}
      </Swiper>

    </div>
  );
};

export default HomeSlide;
