import pallets1 from "../../img/12.jpg";
import cooking from "../../img/cooking-live.jpg";
const Solutions = () => {
  return (
    <div className="solutions_container">
      <div className="solutions_card">
        <h1>MC-GREEN'S SOLUTIONS </h1>
        <div className="solutions_img">
          <img src={cooking} alt="cooking with pallets" />
          <img src={pallets1} alt="cooking with pallets" />
        </div>
      </div>
      <div className="solutions_details">
        <p>
        MC-GREEN'S CO is on a mission to eliminate the need for charcoal in Rwanda. We achieve this by supplying cost-effective and consistent pellets as a clean cooking fuel and by promoting these pellets through the distribution of state-of-the-art biomass gasification stoves. These pellets are uniform in size, shape, and moisture content, making them easy to produce and transport. When used with Tier-4 gasification stoves, they produce a flame almost as clean and convenient as LPG but at a much lower cost compared to charcoal.
        </p>
        <p>
        To make this transition accessible, we offer a 'Lease-to-Own' stove subscription model, reducing the upfront costs for our customers and ensuring they start saving money right from day one. Beyond the immediate benefits to our customers, there are significant advantages for the environment and society at large. Pellet burning is environmentally friendly, relieving the strain on Rwandan forests caused by charcoal production by reducing biomass consumption by up to 90%. What's more, MC-GREEN primarily uses biomass waste left over from forest management, reforestation, and furniture production, contributing to the realization of a circular economy in Rwanda.
        </p>
      </div>
    </div>
  );
};

export default Solutions;
