import def1 from '../../img/imbabura.png'
import empowerWomen from "../../img/empower women.png";
import costImage from '../../img/cost.png'
import jobcreation from '../../img/jobcreation.png'
import wastUtilization from '../../img/wasteUtilization.png'


const SocialImpact = () => {
  return (
    <div className="social_impact">
      <h1>Social, economic, and environmental Impacts</h1>
      <div className="social_impact_container">
        <div className="social_card">
          <div className="social-img">
            <img  src={def1} alt="Improvement Health " />
          </div>
          <div className="social-text">
            <h4> <b>Improved Health</b>:</h4>
            <p>
            The use of clean cooking products reduces indoor air pollution, leading to better respiratory health among households. Fewer respiratory illnesses, especially in women and children, result in a healthier population
            </p>
          </div>
        </div>
        <div className="social_card">
          <div className="social-img">
            <img  src={empowerWomen} alt="Empower women for work" />
          </div>
          <div className="social-text">
            <h4><b>Empowerment of Women</b>:</h4>
            <p>
             By reducing the time and effort required for cooking and fuel collection, women can reallocate their time to education, income-generating activities, and other pursuits.
             </p>
          </div>
        </div>
        <div className="social_card">
          <div className="social-img">
            <img  src={costImage} alt="cost impact" />
          </div>
          <div className="social-text">
          <h4><b> Cost Savings</b>:</h4>
            <p>
            Clean cooking solutions, like pellets and stoves, are often more cost-effective in the long run. Households can save money up 40% compared to charcoal and health expenses related to indoor air pollution.
            </p>
          </div>
        </div>
        <div className="social_card">
          <div className="social-img">
            <img  src={jobcreation} alt="Job creation pic in refugees" />
          </div>
          <div className="social-text">
            <h4> <b>Job Creation</b>:</h4>
            <p>
             The production, distribution, and maintenance of our products create employment opportunities, particularly in rural areas where these technologies are often implemented.
            </p>
          </div>
        </div>
        <div className="social_card">
          <div className="social-img">
            <img  src={wastUtilization} alt="pillates from waste" />
          </div>
          <div className="social-text">
            <h4> <b>Waste Utilization</b>:</h4>
            <p>
             Utilizing biomass waste for pellet production, as opposed to disposing of it, promotes the circular economy and reduces environmental waste.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialImpact;
