const Contact = () => {
  return (
    <div className="contact">
      <h1>Contact us</h1>
      <div className="contact_form">
        <form action="">
        <h2>Get In touch!</h2>
         <div className="form-container">
         <div className="form-group">
            <label>
              <p>Names</p>
              <input type="text" name="names" required />
            </label>
          </div>
          <div className="form-group">
            <label>
              <p> Email</p>
              <input type="email" name="email" required />
            </label>
          </div>
          <div className="form-group">
            <label>
              <p>Message </p>
              <textarea name="message"  rows="5" required></textarea>
            </label>
          </div>

          <div className="btn-group">
            <button type="submit">submit</button>
          </div>
         </div>
        </form>
      </div>
    </div>
   );
}

export default Contact;